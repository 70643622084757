import React, { useState, useEffect } from 'react';

import CustomerSignUpForm from '../components/ContactForm/CustomerSignUpForm';
import RecaptchaProvider from '../components/RecaptchaProvider';
import useQueryParam from '../hooks/use-query-param';

const DEFAULT_BACKGROUND_COLOR = 'white';
const DEFAULT_TEXT_COLOR = 'black';

export default () => {
  const [backgroundColor, setBackgroundColor] = useState(DEFAULT_BACKGROUND_COLOR);
  const [color, setColor] = useState(DEFAULT_TEXT_COLOR);

  function initializeStyle() {
    const initialBackgroundColor = useQueryParam('background') || DEFAULT_BACKGROUND_COLOR;
    const initialColor = useQueryParam('color') || DEFAULT_TEXT_COLOR;

    if (initialBackgroundColor) {
      setBackgroundColor(initialBackgroundColor);
    }
    if (initialColor) {
      setColor(initialColor);
    }
  }

  useEffect(initializeStyle, []);

  const style = { backgroundColor, color, height: '100vh' };

  return (
    <RecaptchaProvider>
      <div style={style}>
        <CustomerSignUpForm referralSource="Inbound" marketingOptIn />
      </div>
    </RecaptchaProvider>
  );
};
